import { RimRenovation } from '@/models/RimRenovation/RimRenovation'
import { useBooking } from '@/store/booking.store'
import { MoreSaleExtended } from '@/types/extendedTypes/configInput'
import type { TireSuggestion, VariationTireSuggestion } from '@/types/generated-types'
declare global {
  interface Window {
    _mfq: any
  }
}
interface UseTracking {
  trackService: (
    serviceType: string | undefined,
    servicePrice: number | undefined,
    priceMessage: string | undefined,
    step: string | undefined,
    type: string | undefined,
    externalUrl: string | undefined,
    eventName: string | undefined,
    moreSaleItems: MoreSaleExtended[]
  ) => void
  trackTiresLegacy: (tire: TireSuggestion, amount: number) => void
  trackTires: (tire: VariationTireSuggestion, amount: number) => void
  trackRimRenovation: (rimRenovation: RimRenovation) => void
  mouseflowFormSubmitAttempt: (formId: string) => void
  mouseflowFormSubmitSuccess: (formId: string) => void
  mouseflowFormSubmitFailure: (formId: string) => void
}

export default (): UseTracking => {
  const bookingStore = useBooking()

  function trackService(
    serviceType: string | undefined,
    servicePrice: number | undefined,
    priceMessage: string | undefined,
    step: string | undefined,
    type: string | undefined,
    externalUrl: string | undefined,
    eventName: string | undefined,
    moreSaleItems: MoreSaleExtended[]
  ): void {
    if (!(window as any).dataLayer) {
      return
    }
    ;(window as any).dataLayer.push({
      serviceType: serviceType,
      servicePrice: servicePrice,
      priceMessage: priceMessage,
      brand: bookingStore.vehicle?.make,
      model: bookingStore.vehicle?.model,
      mileage: bookingStore.typedMileage,
      departmentName: bookingStore.selectedBranch?.name,
      externalURL: externalUrl,
      type: type,
      step: step,
      correlationId: bookingStore.correlationId,
      event: eventName ? eventName : 'EVENTNAME_NOT_DEFINED_IN_BLAZOR',
    })
    moreSaleItems.forEach((addition) => {
      trackService(
        addition.name,
        addition.price?.price,
        undefined,
        step,
        'add_on',
        externalUrl,
        addition.eventName,
        []
      )
    })
  }

  function trackTiresLegacy(tire: TireSuggestion | undefined, amount: number): void {
    if (!(window as any).dataLayer) {
      return
    }
    if (amount <= 0 || !tire) {
      return
    }
    ;(window as any).dataLayer.push({
      tireCategory: tire.productTypeText,
      tireArticleNr: tire.id,
      tireBrandAndName: tire.name,
      tireBrand: tire.brand,
      numberOfPurchasedTires: '' + amount,
      event: 'BookTireCampaignPurchasedTires',
    })
  }

  function trackTires(tire: VariationTireSuggestion | undefined, amount: number): void {
    if (!(window as any).dataLayer) {
      return
    }
    if (amount <= 0 || !tire) {
      return
    }
    ;(window as any).dataLayer.push({
      tireCategory: tire.productType,
      tireArticleNr: tire.id,
      tireBrandAndName: tire.itemName,
      tireBrand: tire.productBrand,
      numberOfPurchasedTires: '' + amount,
      event: 'BookTireCampaignPurchasedTires',
    })
  }

  function trackRimRenovation(rimRenovation: RimRenovation): void {
    const dataLayer = (window as any).dataLayer

    if (!dataLayer) {
      return
    }

    dataLayer.push({
      event: 'BookRimRenovation',
      type: 'booking',
      brand: rimRenovation.vehicle.siteCarMake?.value,
      model: rimRenovation.vehicle.model,
    })
  }

  function mouseflowFormSubmitAttempt(formId: string): void {
    window._mfq = window._mfq || []
    window._mfq.push(['formSubmitAttempt', '#' + formId])
  }

  function mouseflowFormSubmitSuccess(formId: string): void {
    window._mfq = window._mfq || []
    window._mfq.push(['formSubmitSuccess', '#' + formId])
  }

  function mouseflowFormSubmitFailure(formId: string): void {
    window._mfq = window._mfq || []
    window._mfq.push(['formSubmitFailure', '#' + formId])
  }

  return {
    trackService,
    trackTires,
    trackTiresLegacy,
    trackRimRenovation,
    mouseflowFormSubmitAttempt,
    mouseflowFormSubmitSuccess,
    mouseflowFormSubmitFailure,
  }
}
