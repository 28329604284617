import { VehicleRequest } from '@/models/Vehicle/VehicleRequest'
import { ServicePlan } from '@/models/Vehicle/ServicePlan'
import { GRAPHQL } from './http.client'
import { useSettings } from '@/store/settings.store'
import userMonitoring from '@/mixins/userMonitoring'
import ServicePlanQuery from '@/api/queries/ServicePlanQuery.graphql'
import VehicleInformationObsolete from '@/api/queries/VehicleInformationObsolete.graphql'
import VehicleInformation from '@/api/queries/VehicleInformation.graphql'
import { ApolloError } from '@apollo/client'
import { Vehicle } from '@/types/generated-types'
import { useVehicle } from '@/store/vehicle.store'

export const getVehicleInformationGraphQL = (registrationNumber: string): Promise<Vehicle> => {
  const settingStore = useSettings()
  const vehicleStore = useVehicle()

  return GRAPHQL.query<any>({
    query: vehicleStore.useTireHotelInformation ? VehicleInformation : VehicleInformationObsolete,
    variables: {
      registrationNumber: registrationNumber,
      culture: settingStore.culture,
      siteId: settingStore.siteData.siteId,
    },
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  })
    .then((response) => {
      if (response.errors) {
        userMonitoring().trackErrorGraphql(
          'VehicleInformationQuery',
          JSON.stringify(response.errors)
        )
      }
      return response.data.vehicleInformation as Vehicle
    })
    .catch((error: ApolloError) => {
      userMonitoring().trackErrorGraphql(
        'VehicleInformationQuery',
        error,
        JSON.stringify({
          registrationNumber: registrationNumber,
          culture: settingStore.culture,
          siteId: settingStore.siteData.siteId,
        })
      )
      return {} as Vehicle
    })
}

export const getServicePlanRequest = (vehicleRequest: VehicleRequest): Promise<ServicePlan> => {
  // POC graphQL
  return GRAPHQL.query<any>({
    query: ServicePlanQuery,
    variables: {
      culture: vehicleRequest.Culture,
      siteId: vehicleRequest.siteId,
      registrationNumber: vehicleRequest.RegistrationNumber,
      mileage: vehicleRequest.Mileage,
      carMake: vehicleRequest.CarMake,
      model: vehicleRequest.Model,
    },
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  })
    .then((response) => {
      if (response.errors) {
        userMonitoring().trackErrorGraphql(
          'ServicePlanQuery',
          JSON.stringify(response.errors),
          JSON.stringify(vehicleRequest)
        )
      }
      return response.data.servicePlan as ServicePlan
    })
    .catch((error: ApolloError) => {
      userMonitoring().trackErrorGraphql('ServicePlanQuery', error, JSON.stringify(vehicleRequest))
      return {} as ServicePlan
    })
}
