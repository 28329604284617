import { getServicePlanRequest } from '@/api/vehicle.service'
import { ServiceInfo } from '@/models/Vehicle/ServiceInfo'
import { ServicePlan } from '@/models/Vehicle/ServicePlan'
import { VehicleRequest } from '@/models/Vehicle/VehicleRequest'
import { useRouter } from '@/router'
import { defineStore } from 'pinia'
import { useBooking } from './booking.store'
import { useSettings } from './settings.store'
import { useVehicle } from './vehicle.store'
import { useText } from './text.store'

import {
  Day,
  ServiceConfigurationInputType,
  ServiceGroup,
  WorkshopService,
} from '@/types/generated-types'
import { getServiceGroupsRequest, getServiceRequest } from '@/api/services.repository'
import ConfigInput from '@/types/extendedTypes/configInput'
import { operationTypeEnum } from '@/types/extendedTypes/operationType'
import { useMoreSale } from './moresale.store'
import { usePreliminaryBooking } from './preliminary-booking.store'
import { useSubscriptionBooster } from './subscription-booster.store'

interface ServiceState {
  servicePlan: ServicePlan
  serviceInfo: ServiceInfo[]
  servicePlanIsLoading: boolean
  serviceConfigurationIsLoading: boolean
  serviceGroups: ServiceGroup[] | null
  errorCarmakeServiceNotMatch: boolean
}

export const useService = defineStore('service', {
  state: () =>
    ({
      servicePlan: {} as ServicePlan,
      serviceInfo: [] as ServiceInfo[],
      servicePlanIsLoading: false,
      serviceConfigurationIsLoading: false,
      errorCarmakeServiceNotMatch: false,
      serviceGroups: null,
    } as ServiceState),
  actions: {
    onRender() {
      const bookingStore = useBooking()
      bookingStore.clearSelectedBranch()
      bookingStore.clearSelectedTechnician()
    },
    async setup() {
      this.serviceGroups = null

      await this.fetchServiceGroups()
    },
    async complete(selectedService: WorkshopService): Promise<void> {
      const bookingStore = useBooking()
      const preliminaryBookingStore = usePreliminaryBooking()
      const vehicleStore = useVehicle()
      const textStore = useText()
      const moreSaleStore = useMoreSale()

      if (
        !this.hasServiceConfigInputType(
          selectedService as WorkshopService,
          ServiceConfigurationInputType.Mileage
        )
      ) {
        bookingStore.typedMileage = 0
      }

      bookingStore.options.leaveCarTime = null
      bookingStore.options.customerComment = ''
      bookingStore.SelectedDate = {} as Day
      bookingStore.clearSelectedBranch()
      bookingStore.clearSelectedTechnician()
      preliminaryBookingStore.clearPreliminaryBooking()

      this.servicePlan = {} as ServicePlan
      this.addSelectedWorkshopServiceToBooking(selectedService as WorkshopService)
      moreSaleStore.resetCurrentSelectedMoreSales()

      textStore.fetchUsp()
    },

    goToBranchStep() {
      const bookingStore = useBooking()
      const router = useRouter()
      const vehicleStore = useVehicle()
      router.push({
        name: 'branch',
        query: {
          regnr: bookingStore.vehicle.registrationNumber,
          serviceid: bookingStore.options.workshopServiceId,
          ...(bookingStore.typedMileage ? { mileage: bookingStore.typedMileage } : {}),
        },
      })
      document.body.classList.remove('modal-is-open')
    },

    async fetchSevicePlanAndConfiguration(): Promise<void> {
      this.fetchServicePlan()
    },

    async fetchServicePlan(): Promise<void> {
      this.servicePlanIsLoading = true
      const settingsStore = useSettings()
      const vehilceStore = useVehicle()
      const bookingStore = useBooking()

      const vehicleRequest = new VehicleRequest(
        vehilceStore.vehicle.registrationNumber ?? '',
        settingsStore.culture,
        bookingStore.typedMileage,
        vehilceStore.vehicle.siteCarMake?.value ?? '',
        vehilceStore.vehicle.model ?? '',
        settingsStore.siteData.siteId
      )
      await getServicePlanRequest(vehicleRequest).then((servicePlanResponse) => {
        this.servicePlan = servicePlanResponse
        this.serviceInfo = servicePlanResponse?.serviceTypes ?? {}
        this.servicePlanIsLoading = false
      })
    },

    resetState() {
      this.servicePlan = {} as ServicePlan
      this.serviceInfo = {} as ServiceInfo[]
    },

    async fetchServiceGroups(): Promise<void> {
      this.serviceGroups = await getServiceGroupsRequest()
    },

    // graphql
    async addSelectedWorkshopServiceToBooking(workshopService: WorkshopService): Promise<void> {
      const bookingStore = useBooking()

      bookingStore.workshopService = workshopService
      bookingStore.options.bookingType = operationTypeEnum[workshopService?.operationType] ?? ''
      bookingStore.options.bookingName = workshopService?.heading ?? ''
      bookingStore.options.workshopServiceId = workshopService?.id
      bookingStore.serviceConfigurationInputsResponse =
        (bookingStore.workshopService?.serviceConfigurationInputs as ConfigInput[]) ?? []
      bookingStore.clearBookedTires()

      useSubscriptionBooster().setSubscriptionBoosters(workshopService.subscriptionBoosters ?? [])
    },

    async fetchWorkshopServicebyIds(serviceIds: string[]): Promise<void> {
      await getServiceRequest(serviceIds).then((workshopServcie) => {
        this.addSelectedWorkshopServiceToBooking(workshopServcie)
      })
    },

    async fetchWorkshopServicebyId(serviceId: string): Promise<WorkshopService> {
      return await getServiceRequest([serviceId])
    },

    hasServiceConfigInputs(workshopService: WorkshopService): boolean {
      return (
        (workshopService.serviceConfigurationInputs &&
          workshopService.serviceConfigurationInputs.length > 0) ??
        false
      )
    },
    hasServiceConfigInputType(
      workshopService: WorkshopService,
      inputType: ServiceConfigurationInputType
    ): boolean {
      const wheelSalesConfigInputIndex =
        workshopService.serviceConfigurationInputs?.findIndex(
          (serviceConfiguration) => serviceConfiguration.serviceConfigurationInputType == inputType
        ) ?? -1
      return wheelSalesConfigInputIndex != -1
    },
  },
})
