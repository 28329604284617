<template>
  <HeaderTeleporter />
  <main :class="$route.name">
    <div v-if="settingsStore.evaluatingRoutes" class="spinner_wrapper">
      <Spinner />
    </div>
    <router-view v-else />
    <UspList v-if="showUsp()" />
  </main>
  <DebugWidget />
  <!-- <ModalPWAInstall/> -->
  <ModalReservationTimer />
  <ModalReservationDone />
  <ModalCommonMessage />
</template>

<script lang="ts" setup>
import { onMounted, ref, watch, nextTick } from 'vue'
import { imboxchat } from '@/mixins/imboxchat'
import { useSettings } from './store/settings.store'
import { useRoute, useRouter } from 'vue-router'
import '@/mixins/customMethods/includesObj'
import userMonitoring from './mixins/userMonitoring'
import HeaderTeleporter from '@/components/framework/HeaderTeleporter.vue'
import ModalReservationTimer from '@/components/framework/ModalReservationTimer.vue'
import ModalReservationDone from '@/components/framework/ModalReservationDone.vue'
import ModalCommonMessage from '@/components/framework/ModalCommonMessage.vue'
import UspList from '@/components/framework/UspList.vue'
import Spinner from '@/components/generic/Spinner.vue'
import DebugWidget from '@/components/debugComps/DebugWidget.vue'

const route = useRoute()
const settingsStore = useSettings()
const router = useRouter()

const main = ref()

onMounted(async () => {
  const chat = new imboxchat()
  if (typeof import.meta.env.VITE_ImBoxId === 'string') {
    chat.initChat(import.meta.env.VITE_ImBoxId)
  }
})

function handleTabs() {
  function handleFirstTab(e: KeyboardEvent) {
    //TODO: deprecated
    if (e.keyCode === 9) {
      document.body.classList.add('user-is-tabbing')

      window.removeEventListener('keydown', handleFirstTab)
      window.addEventListener('mousedown', handleMouseDownOnce)
    }
  }

  function handleMouseDownOnce() {
    document.body.classList.remove('user-is-tabbing')

    window.removeEventListener('mousedown', handleMouseDownOnce)
    window.addEventListener('keydown', handleFirstTab)
  }

  window.addEventListener('keydown', handleFirstTab)
}

function showUsp() {
  return (
    route.name === 'vehicle' ||
    route.name === 'service' ||
    route.name === 'selected-workshopservice' ||
    route.name === 'branch'
  )
}

function removeShellClass() {
  document.body.classList.remove('shell')
}

// handleServiceWorkerEvents()
//
removeShellClass()
handleTabs()
userMonitoring().trackAction('BOOKING FLOW - Enter application')

watch(
  () => router.currentRoute.value.path,
  function () {
    nextTick(() => {
      setTimeout(() => {
        document.body.setAttribute('tabindex', '-1')
        document.body.focus()
      }, 0)
    })
  }
)
</script>
<style lang="scss">
#app {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
</style>
<style lang="scss" scoped>
@import './src/assets/scss/base';
main {
  background-color: var(--color-bilia-sand-50);
  display: grid;
  @include main-grid;
  grid-column: main;
  align-content: space-between;
  flex-grow: 1;
  // routes
  &.vehicle {
    display: flex;
    flex-direction: column;
  }
  &.reservation {
    grid-template-rows: auto auto 1fr auto;
  }
}
.spinner_wrapper {
  grid-column: full;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmation {
  background-color: var(--color-bilia-sand-100);
}
</style>
