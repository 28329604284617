import { GRAPHQL, HTTP, currentSite } from './http.client'
import userMonitoring from '@/mixins/userMonitoring'
import {
  SubmittedPreliminaryBookingResponseType,
  AddMoreSaleQueryInput,
  BookingResponse,
  StatusCodeResponse,
  SubmitBookingQueryInput,
} from '@/types/generated-types'
import PreliminaryBooking from '@/api/queries/PreliminaryBooking.graphql'
import SubmitBooking from '@/api/queries/SubmitBooking.graphql'
import AddMoreSale from '@/api/queries/AddMoreSaleQuery.graphql'
import { ApolloError } from '@apollo/client'

export const submitBookingRequest = async (
  submitBookingQuery: SubmitBookingQueryInput
): Promise<BookingResponse> => {
  try {
    const response = await GRAPHQL.query<any>({
      query: SubmitBooking,
      variables: { submitBookingQuery: { ...submitBookingQuery } },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    })
    if (response.errors) {
      userMonitoring().trackErrorGraphql(
        'SubmitBooking',
        JSON.stringify(response.errors),
        JSON.stringify(submitBookingQuery)
      )
    }
    return response.data.submitBooking as BookingResponse
  } catch (error) {
    userMonitoring().trackErrorGraphql('SubmitBooking', error)
    return {} as BookingResponse
  }
}

export const addMoreSalesToBookingRequest = async (
  addMoreSaleRequest: AddMoreSaleQueryInput
): Promise<StatusCodeResponse> => {
  try {
    const response = await GRAPHQL.query<any>({
      query: AddMoreSale,
      variables: { addMoreSaleQuery: { ...addMoreSaleRequest } },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    })
    if (response.errors) {
      userMonitoring().trackErrorGraphql(
        'AddMoreSale',
        JSON.stringify(response.errors),
        JSON.stringify(addMoreSaleRequest)
      )
    }
    return response.data as StatusCodeResponse
  } catch (error) {
    userMonitoring().trackErrorGraphql('AddMoreSale', error, JSON.stringify(addMoreSaleRequest))
    return {} as StatusCodeResponse
  }
}

export const createPreliminaryBookingByGraphQL = async (submitPreliminaryBooking: {
  SiteId: string
  ServiceId: string
  ContainerId: number
  Date: string
  RegionCode: number
  IsTireHotel: boolean
  branchId: string
}): Promise<SubmittedPreliminaryBookingResponseType> => {
  const queryVariables = {
    siteId: submitPreliminaryBooking.SiteId,
    serviceId: submitPreliminaryBooking.ServiceId,
    containerId: submitPreliminaryBooking.ContainerId,
    date: submitPreliminaryBooking.Date,
    regionCode: submitPreliminaryBooking.RegionCode,
    isTireHotel: submitPreliminaryBooking.IsTireHotel,
    branchId: submitPreliminaryBooking.branchId,
    indexPrefix: currentSite?.indexPrefix,
  }
  try {
    const response = await GRAPHQL.query<any>({
      query: PreliminaryBooking,
      variables: queryVariables,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    })
    if (response.errors) {
      userMonitoring().trackErrorGraphql(
        'PreliminaryBooking',
        JSON.stringify(response.errors),
        JSON.stringify(queryVariables)
      )
    }
    return response.data.submitPreliminaryBooking as SubmittedPreliminaryBookingResponseType
  } catch (error) {
    userMonitoring().trackErrorGraphql('PreliminaryBooking', error, JSON.stringify(queryVariables))
    return {} as SubmittedPreliminaryBookingResponseType
  }
}
