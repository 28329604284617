<template>
  <div>
    <!-- <Disclosure />  POC for using bricks components -->
    <FadeTransition>
      <component
        :is="showOnlyInput ? 'div' : 'form'"
        v-if="!state.isCompleted && !objectIsEmpty(settingsStore.settings)"
        id="regNrForm"
        :class="[
          showOnlyInput ? 'reg-nr-input-wrapper' : 'define-vehicle',
          'reg-number-length-' + settingsStore.settings.registrationNumberLength,
        ]"
      >
        <div v-if="showHeader" class="intro">
          <h1 class="h2">{{ textResource('vehicleText', 'enterRegistrationNumberHeading') }}</h1>

          <p>{{ textResource('vehicleText', 'enterRegistrationNumberText') }}</p>
        </div>
        <InputField
          id="input-regnr"
          ref="inputRegnr"
          :mask="settingsStore.settings.registrationNumberMask"
          class="reg-number"
          :label="textResource('vehicleText', 'registrationNumber')"
          :is-loading="vehicleStore.vehicleDataIsLoading"
          :placeholder="textResource('vehicleText', 'registrationNumberPlaceholder')"
          type="search"
          autocomplete="on"
          :value-is-valid="!state.showDefineVehicle"
          :pattern="settingsStore.settings.registrationNumberRegexp"
          :input-value="state.initReg"
          :skip-blur-validation="true"
          :disabled="disabled"
          hidelabel
          required
          @keydown.enter.prevent="onSubmitVehicle"
          @onValid="findVehicle"
          @input="onRegnumerInput"
        >
          <template #afterInputSlot>
            <Icon :name="textResource('vehicleText', 'regNrSource')" />
          </template>
        </InputField>
        <Button
          v-if="!showOnlyInput"
          id="submitButton"
          ref="submitButton"
          class="button-full-height"
          data-dd-action-name="REGNUMBER - Used next button"
          :disable-button="submitIsDisabled()"
          @OnClick="onSubmitVehicle"
        >
          {{ textResource('generalText', 'next') }}
        </Button>
        <VehicleInfo
          v-if="Object.keys(vehicleStore.vehicle).length"
          :show-error="state.showDefineVehicle"
          :vehicle="vehicleStore.vehicle"
        />
        <!-- <VehicleType v-if="serviceAndVehicleStore.vehicle.IsTransportCar" /> -->
        <div class="manual-define">
          <DefineVehicleManual v-if="state.showDefineVehicle" />
          <Button
            v-if="state.showDefineVehicle && !showOnlyInput"
            id="submitButtonDefine"
            ref="submitButton"
            :disable-button="!manualCarValid(vehicleStore.vehicle)"
            data-dd-action-name="REGNUMBER - Manual vehicle input submit"
            @OnClick="onSubmitVehicle"
          >
            {{ textResource('generalText', 'next') }}
          </Button>
        </div>
      </component>
    </FadeTransition>
  </div>
</template>

<script lang="ts" setup>
import { reactive, ref, onMounted } from 'vue'
import useTracking from '@/mixins/tracking/useTracking'
import { useBooking } from '@/store/booking.store'
import { useService } from '@/store/service.store'
import { useSettings } from '@/store/settings.store'
import objectIsEmpty from '@/mixins/sharedFunctions/objectIsEmpty'
import { useVehicle } from '@/store/vehicle.store'
import textResource from '@/mixins/sharedFunctions/textResource'
import userMonitoring from '@/mixins/userMonitoring'
import VehicleInfo from '@/components/serviceandvehicle/VehicleInfo.vue'
import InputField from '@/components/generic/InputField.vue'
import Button from '@/components/generic/Button.vue'
import Icon from '@/components/generic/Icon.vue'
import DefineVehicleManual from '@/components/serviceandvehicle/DefineVehicleManual.vue'
import FadeTransition from '@/components/generic/FadeTransition.vue'
import type { Vehicle } from '@/types/generated-types'

const props = defineProps({
  initRegNumber: {
    type: String,
    required: false,
    default: '',
  },
  showHeader: {
    type: Boolean,
    required: false,
    default: true,
  },
  showOnlyInput: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['complete', 'vehiclechanged', 'reg-nr'])

const vehicleStore = useVehicle()
const bookingStore = useBooking()
const serviceStore = useService()
const settingsStore = useSettings()
const { mouseflowFormSubmitSuccess } = useTracking()

const submitButton = ref()

const state = reactive({
  regNumber: '',
  showDefineVehicle: false,
  manualDefineVehicleIsValid: false,
  vehicleIsFind: false,
  isCompleted: false,
  initReg: '',
  carMakeWasChanged: false,
})

async function findVehicle(registrationNumber: string): Promise<void> {
  const oldCarMake = vehicleStore.vehicle.make
  state.regNumber = registrationNumber.replace(/\s/g, '')
  state.vehicleIsFind = false

  if (
    !vehicleStore.vehicleDataIsLoading &&
    vehicleStore.vehicle.registrationNumber != state.regNumber
  ) {
    const foundCar = await vehicleStore.complete(state.regNumber)

    if (!foundCar) {
      vehicleStore.vehicle.registrationNumber = state.regNumber
      state.showDefineVehicle = true
    } else {
      serviceStore.setup()
      state.vehicleIsFind = true
      state.carMakeWasChanged = oldCarMake != vehicleStore.vehicle.make
    }
  }
}

function submitIsDisabled(): boolean {
  return !vehicleStore.vehicle.make || state.regNumber.length < 1
}

async function onSubmitVehicle() {
  if (!submitIsDisabled()) {
    serviceStore.resetState()
    mouseflowFormSubmitSuccess('regNrForm')
    vehicleStore.vehicle.registrationNumber = state.regNumber
    bookingStore.resetBooking()
    bookingStore.vehicle = vehicleStore.vehicle
    if (!objectIsEmpty(bookingStore?.vehicle)) {
      emit('complete')
      emit('vehiclechanged')
      state.isCompleted = true
      vehicleStore.regNumberIsDone = true
      vehicleStore.goToServiceStep(state.carMakeWasChanged)
    } else {
      state.isCompleted = true
      vehicleStore.regNumberIsDone = true
      emit('complete')
    }
  }
}

function manualCarValid(car: Vehicle): boolean {
  if (objectIsEmpty(car)) {
    return false
  }
  if (car.make && car.model && car.year) {
    return true
  }
  return false
}

function onRegnumerInput(regnumber: string): void {
  state.showDefineVehicle = false
  if (regnumber.length <= settingsStore.settings.registrationNumberLength) {
    state.showDefineVehicle = false
    vehicleStore.resetState()
  }
  emit('reg-nr', regnumber)
}

function onEnterInput(event: Event) {
  event.preventDefault()
  if (state.vehicleIsFind) {
    onSubmitVehicle()
    userMonitoring().trackAction('REGNUMBER - Used enter key')
  }
}

onMounted(() => {
  state.initReg = props.initRegNumber
})
</script>

<style lang="scss" scoped>
@import './src/assets/scss/base';

.manual-define {
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  row-gap: var(--size-double);
}

.is-loading--spin-large {
  display: flex;
  justify-content: center;
  min-height: 10rem;
  align-items: center;
  align-content: center;
}

.reg-nr-input-wrapper {
  max-width: 16rem;
  width: 100%;
}

.define-vehicle {
  display: grid;
  grid-template-columns: 13rem auto;
  column-gap: var(--size-full);
  row-gap: 0;
  align-content: center;
  justify-content: center;
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--size-full);
  padding: var(--size-full);

  @media (max-width: 365px) {
    .input-wrapper,
    .define-vehicle-manual,
    .button {
      grid-column: span 2;
    }
  }
  .button {
    align-self: flex-end;
    width: 100%;
    white-space: nowrap;
    padding: 0.9em 1rem;
  }
  .button-full-height {
    height: 100%;
  }
}

.intro {
  grid-column: span 2;
  text-align: center;
  padding-bottom: 2rem;

  h1 {
    margin: 0;
  }

  p {
    margin: 0;
  }
}

.reg-number {
  flex-shrink: 0;

  :deep(input) {
    font-size: var(--font-xxl);
    padding: 0.3rem 0 0.3rem 2rem;
    text-transform: uppercase;
    //@include hellix-semibold;
    @include roboto-mono;
    &:not(:focus) + .icon {
      filter: grayscale(100%);
      opacity: 0.6;
    }
  }
  .icon {
    position: absolute;
    display: block;
    height: 2.4rem;
    top: 0.6rem;
    left: 0.5rem;
    & :deep(svg) {
      height: 100%;
    }
    @include safari-only {
      top: 0.7rem;
    }
  }
  &:not(.invalid) :deep(input::-webkit-search-cancel-button) {
    display: none;
  }
  &.is-loading--spin:before {
    right: 0.2em;
    top: 1.2rem;
    @include safari-only {
      right: 0.3rem;
      top: 1.5rem;
      @include media-large {
        right: 0.25rem;
        top: 1.4rem;
      }
    }
  }
  &:after {
    bottom: 0.65rem !important;
  }
}

.vehicle-info {
  grid-column: span 2;
  margin-bottom: var(--size-half);
}

:deep(.vehicle-info) {
  min-height: calc(1.2 * 1rem);
}

// used by norge
.reg-number-length-7 {
  grid-template-columns: 15rem 1fr;
  .reg-number {
    width: 15rem;
  }
}
</style>
